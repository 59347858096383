import React, { useState, useRef, useEffect } from 'react'
import { Button } from "antd"
import { ButtonProps } from 'antd/lib/button';

type Props = { 
		onClick?: () => Promise<any>,
		onSubmit?: () => Promise<void>
}


const AsyncButton = ({ onClick, onSubmit, loading, disabled, ...rest }: ButtonProps & Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const isMounted = useRef(true);
	
	useEffect(() => () => { isMounted.current = false; }, []);

	async function handleClick() {
		setIsLoading(true);
		try {
			if(onClick) {
				await onClick();
			}
			if(onSubmit) { 
				await onSubmit();
			}
		} catch {}
		if(isMounted.current) {
			setIsLoading(false);
		}
	}

	return (
			<Button loading={loading || isLoading} disabled={isLoading || disabled} onClick={handleClick} {...rest} />		
	)
}
export default AsyncButton;
