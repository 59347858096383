import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import { RestaurantMenu } from "./RestaurantMenu";
import { NoMenu } from "./NoMenu";
import { Upload, message, Typography, Divider, Card, Space } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile, UploadChangeParam } from "antd/lib/upload/interface";
import { uploadRestaurantImage, restaurantUrl, restaurantNameRules, restaurantDescriptionRules } from "./common";
import { Optional } from "../../../util";
import QRCode from 'qrcode';
import ModifableText from "../../../components/ModifableText";

export function RestaurantView({ restaurant }: { restaurant: Restaurant; }) {
	const context = useContext(AppContext);
	const [qr, setQr] = useState<Optional<string>>(Optional.Nothing());

	useEffect(() => {
		QRCode.toDataURL(restaurantUrl(restaurant.id), { margin: 0 })
			.then(url => {
				setQr(Optional.Just(url));
			})
			.catch((err: any) => {
				console.error(err);
			});
	}, [restaurant, context.restaurants[restaurant.id].menuId]);
	
	// function handleUploadChange(file: UploadChangeParam<UploadFile>) {
	// 	console.log(file);
	// }

	async function handelRemoveImage(file: UploadFile) {
		const hideLoading = message.loading("Uploading image", 0);
		try {
			await context.removeRestaurantImage({ restaurantId: restaurant.id, imageUrl: file.url! })
			hideLoading();
			message.success("Image removed successfully");
			return true;
		} catch (err) {
			hideLoading();
			message.error("There was an error while deleting the image");
			return false;
		}
	}

	async function handleUploadImage(file: UploadFile) {
		const hideLoading = message.loading("Uploading image", 0);
		try {
			const imageUrl = await uploadRestaurantImage(context.auth.getOrThrow().uid, file as any);
			await context.addRestaurantImage({ restaurantId: restaurant.id, imageUrl })
			hideLoading();
			message.success("Image added successfully");
			file.url = imageUrl;
			file.thumbUrl = imageUrl;
			return imageUrl;
		} catch (err) {
			hideLoading();
			message.error("There was an error while uploading the image");
			console.error(err);
			throw err;
		}
	}

	return <Space direction="vertical" size="large" style={{ width: "100%" }}>
		<Card title="Restaurant info">
			<ModifableText 
				rules={restaurantNameRules} 
				label={"Name"} 
				text={restaurant.name} 
				onFinish={newName => context.changeRestaurantName({ restaurantId: restaurant.id, name: newName })} 
			/>
			<br />
			<ModifableText
				rules={restaurantDescriptionRules}
				label={"Description"}
				text={restaurant.description || ""}
				onFinish={newDescription => context.changeRestaurantDescription({ restaurantId: restaurant.id, description: newDescription })}
				multiline={true}
			/>
		</Card>

		<Card title="Check out your restaurant">
			<img src={qr.orElse("")} alt="qr code" /><br />
			or click <a href={restaurantUrl(restaurant.id)}>here</a>.
		</Card>

		<Card title="Menu">
		{restaurant.menuId === undefined
			|| !(restaurant.menuId in context.menus)
			? <NoMenu restaurantId={restaurant.id} />
			: <RestaurantMenu restaurantId={restaurant.id} />}
		</Card>

		<Card title="Restaurant images">
			<Upload
				action={handleUploadImage}
				multiple={false}
				accept="image/png,image/jpeg"
				onRemove={handelRemoveImage}
				listType="picture-card"
				defaultFileList={(restaurant.imagesUrls ?? []).map(v => ({ uid: v, size: 0, name: v, thumbUrl: v, type: "picture", url: v }))}			
			>
				{(restaurant.imagesUrls ?? []).length < 4 && <><UploadOutlined /> Click to add an image</>}
			</Upload>
		</Card>
	</Space>;
}
