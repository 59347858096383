import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { MenuChanger } from "./MenuChanger";

export function RestaurantMenu({ restaurantId }: { restaurantId: string; }) {
	const context = useContext(AppContext);

	return <div>
		Current restaurant menu:<br/> 
		<MenuChanger
			currentMenuId={context.restaurants[restaurantId].menuId}
			restaurantId={restaurantId} 
		/>
	</div>;
}
