import React, { useContext } from 'react'
import { AppContext } from "../../context/AppContext";
import { Typography, Button } from "antd";

export default function Profile() {
	const context = useContext(AppContext)
	function logout() {
		context.logout();
	}
	return (
		<div style={{padding: 10}}>
			<Typography.Title level={4}>Welcome {context.auth.map(v => v.displayName || v.email, () => "")}!</Typography.Title>

			<Button onClick={logout}>Logout</Button>
		</div>
	)
}
