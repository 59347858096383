import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import AsyncButton from "../../components/AsyncButton";
import { Button, Form, Input, message, Divider } from "antd";
import Icon, { GoogleCircleFilled } from "@ant-design/icons";
import { ReactComponent as GoogleLogo } from '../../svg/google.svg';
import { Store } from "antd/lib/form/interface";


export default function Login() {
	const context = useContext(AppContext);

	async function login(data: Store) {
		const { email, password } = data;
		try {
			const hideLoading = message.loading("Signing in. Please wait");
			const us = await context.login(email, password)
			hideLoading();
			message.success("Welcome back " + us.user?.email)
		} catch(err) {
			console.error(err);
			message.error(err.message);
		}
	}

	return <div 
			style={{ 
					display: "flex"
				, flexDirection: "column"
				, flex: 1
				, alignItems: "center"
				, paddingTop: 50
				, backgroundColor: "#f5f5f5"
				, height: "100vh"
			}}
		> 
		<div style={{ width: 300, paddingLeft: 20 }}><h1>Login</h1></div>
		<Form 
			onFinish={login}
			layout="vertical"
			style={{ 
				width: 300,
				borderRadius: 15,
				padding: 20,
				boxShadow: "rgb(0 0 0 / 23%) 0px 2px 5px 0px" 
				, backgroundColor: "#fff"
			}}
		>
			<Form.Item
				label="E-mail"
				name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
			>
				<Input />
			</Form.Item>
			<Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
				]}
				style={{marginBottom: 0}}
      >
        <Input.Password autoComplete="current-password" />
      </Form.Item>
			<Form.Item>
				<Link to="reset" style={{float: "right"}}>Forgot password?</Link>
			</Form.Item>
			<div style={{textAlign: "center"}}>
				<AsyncButton type="primary" htmlType="submit" style={{padding: "5px 15px"}}>Login</AsyncButton>
			</div>
			<Divider>or</Divider>
			<div style={{ marginTop: 30 }}>
				<GoogleLoginButton />
			</div>
		</Form>
		<div style={{ marginTop: 30 }}>or <Link to="register">Sign up here</Link></div>
	</div>
}

function GoogleLoginButton() {
	const context = useContext(AppContext);
	return <Button 
		onClick={context.loginGoogle} 
		style={{ width: "100%", height: "fit-content", padding: "5px 15px" }}
		icon={<Icon component={GoogleLogo} />}
	>
		Sign in with Google
	</Button>
}