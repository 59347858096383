import React, { useContext } from "react"
import { Link } from "react-router-dom";
import { Form, Input, message, Checkbox } from "antd";
import { Store } from "antd/lib/form/interface";
import AsyncButton from "../../components/AsyncButton";
import { AppContext } from "../../context/AppContext";


export default function Register() {
	const context = useContext(AppContext);
	async function register(values: Store) {
		const { email, password } = values;
		try {
			await context.register(email, password);
			message.success("Account created succeffully!")
		} catch(err) {
			console.error(err);
			message.error(err.message);
		}
	}

	return <div 
		style={{ 
				display: "flex"
			, flexDirection: "column"
			, flex: 1
			, alignItems: "center"
			, paddingTop: 50
			, backgroundColor: "#f5f5f5"
			, height: "100vh"
		}}
	> 
		<div style={{ width: 300, paddingLeft: 20 }}><h1>Register</h1></div>
		<Form
			layout="vertical"
			style={{ 
				width: 300,
				borderRadius: 15,
				padding: 20,
				boxShadow: "rgb(0 0 0 / 23%) 0px 2px 5px 0px" 
				, backgroundColor: "#fff"
			}}
			onFinish={register}
		>
			<Form.Item
				label="E-mail"
				name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
			>
				<Input />
			</Form.Item>	
	
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
				]}

				hasFeedback
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>
			<Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          { validator:(_, value) => value ? Promise.resolve() : Promise.reject('Should accept agreement') },
        ]}
      >
        <Checkbox>
          I have read the <a href="">agreement</a>
        </Checkbox>
      </Form.Item>
			<Form.Item style={{textAlign: "center"}} >
				<AsyncButton type="primary" htmlType="submit">Sign Up</AsyncButton>
			</Form.Item>
		</Form>
		<div style={{ marginTop: 30 }}>or <Link to="login">Sign in</Link></div>
	</div>
}