import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import firebase from "firebase";
import { Optional } from "../util";

type GetMenuData = {
	restaurantName: string,
	restaurantDescription: string,
	restaurantImagesUrls: string[]
	menu: MenuBase | null,
	categories: null | (MenuCategoryBase & { id: string, entries: (CategoryEntryBase & { id: string })[] })[]
}

async function getMenu(id: string) {
	const res = await firebase.functions().httpsCallable("getMenu")({ id });
	return res.data as GetMenuData;
}

export default function Public() {
	const [loading, setLoading] = useState(true);
	const [resp, setResp] = useState<Optional<GetMenuData>>(Optional.Nothing());
	const { id } = useParams();

	useEffect(() => {
		setLoading(true);
		getMenu(id)
			.then(m => setResp(Optional.Just(m)))
			.catch(err => {
				document.body.innerHTML += JSON.stringify(err)
				console.error(err)
			})
			.finally(() => setLoading(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
			loading ? (<span>Loading...</span>) 
				: resp.map(r => <Menu data={r} />, () => <span>Error...</span>)
	)
}

function Menu({ data }: { data: GetMenuData }) {
	return <div style={{ backgroundColor: "#f8f8f8", display: "flex", flexDirection: "column", flexGrow: 1, flexShrink: 0, minHeight: "100vh" }}>
		{<img 
				src={data.restaurantImagesUrls.length > 0 ? data.restaurantImagesUrls[0] : "https://lorem.photos/200/200"} 
				style={{
					width: "100%",
					height: 150,
					objectFit: "cover",
					borderBottomRightRadius: 20,
					borderBottomLeftRadius: 20
				}}
				
			/>}
		<div style={{
				textAlign: "center",
				backgroundColor: "#fff",
				zIndex: 2,
				alignSelf: "center",
				width: "80%",
				height: "100%", 
				padding: 10,
				borderRadius: 20,
				position: "relative",
				top: -100,
				boxShadow: "0px 0px 11px -1px rgba(0,0,0,0.58)"
			}}>
				<h1>{data.restaurantName}</h1>
				{/* <h3>{data.restaurantDescription}</h3> */}
				<h2>{data.menu?.title ?? ""}</h2>
				{/* <h4>{data.menu.description}</h4> */}
			</div>
		<div style={{ position: "relative", top: -100, padding: 10, flex: 1, flexGrow: 1 }}>
			{Object.values(data.categories ?? {})
				.sort((a, b) => a.index - b.index)
				.map(cat => (
					<div key={cat.id} style={{ marginTop: 50 }}>
						<div style={{ display: "flex" }}>
							<div><h3>{cat.title}</h3></div>
						</div>
						<div>
							{Object.values(cat.entries)
								.sort((a, b) => a.index - b.index).map(entry => (
									<div key={entry.id} style={{ paddingTop: 10, paddingBottom: 10, borderBottom: "1px solid lightgray", display: "flex", justifyContent: "space-between" }}>
										<div>
											<span>{entry.name}</span><br/>
											<span style={{ opacity: 0.5 }}>{entry.description}</span>
										</div>
										<div>
											<span>{entry.price}</span>
										</div>
									</div>
								))
							}
						</div>
					</div>
				))
			}
		</div>
	</div >
}