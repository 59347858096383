import React, { useContext, useState, useEffect } from "react"
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AppContext } from "../../context/AppContext"
import { Optional } from "../../util";

const stripePromise = loadStripe("pk_test_51H6zu9B9FAUr19jc59gFtrApN55oS0CbMxcNhUMPwz6Eb7ukiWzpY9pn4CD5cBXRhlqL9Gm8iEBnJZV0GSMFgakX00PBfMfPr9");

export default function Subscription() {

	// if(!context.account.isPresent() || context.account.get()?.stripeCustomer === undefined) {
	// 	return <CreateAccount />
	// }
	return <Elements stripe={stripePromise}>
		<div style={{padding: 10}}>
			<SubscribeForm />
		</div>
	</Elements>
}

const CARD_ELEMENT_OPTIONS = {
  // style: {
  //   base: {
  //     color: "#32325d",
  //     fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //     fontSmoothing: "antialiased",
  //     fontSize: "16px",
  //     "::placeholder": {
  //       color: "#aab7c4",
  //     },
  //   },
  //   invalid: {
  //     color: "#fa755a",
  //     iconColor: "#fa755a",
  //   },
  // },
};

function CardSection() {
  return (
    <label>
      Card details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
};

async function checkSubscription(f: () => Promise<StripeSubscription>, setter: React.Dispatch<React.SetStateAction<Optional<StripeSubscription>>>) {
	return f().then(sub => setter(Optional.Just(sub)));
}

function SubscribeForm() {
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [subscription, setSubscription] = useState<Optional<StripeSubscription>>(Optional.Nothing());
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if(context.account.map(x => x.subscriptionId !== undefined, () => false)) {
			checkSubscription(context.retrieveCurrentSubscription, setSubscription)
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [context.account]);

	if(stripe === null || elements === null || loading) {
		return <span>Loading...</span>
	}

	async function handleSubmitSubscription() {
		if(stripe === null || elements === null) {
			console.error("stripe or elements are null");
			return;
		}
		const cardElement = elements.getElement(CardElement);
		if(cardElement === null) {
			console.error("Card element is null");
			return;
		}

		// If a previous payment was attempted, get the lastest invoice
		const latestInvoicePaymentIntentStatus = localStorage.getItem(
			'latestInvoicePaymentIntentStatus'
		);
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (error || paymentMethod === undefined) {
			console.log('[createPaymentMethod error]', error);
		} else {
			console.log('[PaymentMethod]', paymentMethod);
			const paymentMethodId = paymentMethod.id;
			if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
				// Update the payment method and retry invoice payment
				// const invoiceId = localStorage.getItem('latestInvoiceId');
				// retryInvoiceWithNewPaymentMethod({
				// 	customerId,
				// 	paymentMethodId,
				// 	invoiceId,
				// 	priceId,
				// });
			} else {
				// Create the subscription
				setLoading(true);
				try {
					setSubscription(Optional.Just(await context.createSubscription({ paymentMethodId, priceId: "price_1H6zulB9FAUr19jczJs1nHGa" })));
				} catch(err) {
					console.error(err);
				}
				setLoading(false);
			}
		}
	}

	async function handleCancelSubscription() {
		await context.cancelCurrentSubscription();	
		checkSubscription(context.retrieveCurrentSubscription, setSubscription);
	}

	async function handleReactivateSubscription() {
		await context.reactivateCurrentSubscription();	
		checkSubscription(context.retrieveCurrentSubscription, setSubscription);	
	}

	function SubForm() {
		return <form onSubmit={e => { e.preventDefault(); handleSubmitSubscription(); }}>
			Selected plan: € 6.99 <br/>
			<CardSection />
			<button disabled={!stripe || loading}>Confirm order</button>
		</form>
	}
	
	return subscription.ifMap(
			sub => sub.status === "active" || sub.current_period_end > Date.now() / 1000,
			sub => (<div>
				Your are all set up!
				{sub.cancel_at_period_end ?
						<div>
							<div>
								Your subscription is cancelled and will end on {new Date(1000 * sub.current_period_end).toLocaleString()}
							</div>
							Subscribe again: <button onClick={handleReactivateSubscription}>Reactivate</button>
						</div>
					:	<div>
							Next charge: {sub.plan.amount_decimal.replace(/(\d+)(\d\d)$/, "$1.$2") + " " + sub.plan.currency} on {new Date(1000 * sub.current_period_end).toLocaleString()}<br/>
							<button onClick={handleCancelSubscription}>Cancel subscription</button>
						</div> 
				}
			</div>)
		, () => <SubForm />);
}
