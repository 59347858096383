import React, { useContext, useState, useEffect, useRef } from 'react'
import { AppContext } from '../../context/AppContext';
import { Optional } from '../../util';
import { Button, Select, Card, Space, Divider, List, Modal, Typography } from "antd";
import AddMenuModal from "./Menu/AddMenuModal";
import ModifableText from "../../components/ModifableText";
import { menuNameRules, menuDescriptionRules, categoryNameRules, categoryDescriptionRules, entryNameRules, entryDescriptionRules, entryPriceRules } from "./Menu/common";
import { PlusOutlined, MinusOutlined, UpOutlined, DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import AsyncButton from "../../components/AsyncButton";
import FlipMove from "react-flip-move"
import Meta from "antd/lib/card/Meta";
import AddCategoryModal from "./Menu/AddCategoryModal";
import AddEntryModal from "./Menu/AddEntryModal";
export default function Menu() {
	const context = useContext(AppContext);
	const [selectedMenu, setSelectedMenu] = useState<Optional<Menu>>(Optional.Nothing());
	const localMenusRef = useRef(context.menus);


	useEffect(() => {
		if(!selectedMenu.isPresent() 
			|| Object.keys(localMenusRef.current).length < Object.keys(context.menus).length) {
			const latest = Object.values(context.menus).sort((a, b) => b.createdAt - a.createdAt)[0];
			setSelectedMenu(Optional.maybe(latest));
		}
	}, [context.menus]);

	return <div style={{ flex: 1 }}>
		<div 
			style={{ 
					display: "flex"
				, flexDirection: "row"
				, justifyContent: "space-between"
				, borderBottom: "1px solid lightgray"
				, backgroundColor: "#fff"
				, padding: 5
			}}
		>
			<div>
				<Select
					notFoundContent="You don't have menus"
					style={{ minWidth: 200 }}
					placeholder="Select menu"
					value={selectedMenu.get()?.id}
					options={Object.values(context.menus)
						.sort((a, b) => b.createdAt - a.createdAt)
						.map(menu => ({ label: menu.title, value: menu.id }))
					}
					onChange={e => setSelectedMenu(
						Optional.maybe(context.menus[e])
					)}
				/>
			</div>
			<div>
				<AddMenuModal />
			</div>
		</div>
		<div style={{paddingRight: 10, paddingLeft: 10}}>
			{selectedMenu.map(m => <MenuView menu={m}/>, () => <></>)}
		</div>
	</div>
}

function MenuView({ menu }: { menu: Menu } ) {
	const context = useContext(AppContext);

	async function handleTitleChanged(newTitle: string) {
		return context.changeMenuTitle({ menuId: menu.id, title: newTitle });
	}

	async function handleDescriptionChanged(newDescription: string) {
		return context.changeMenuDescription({ menuId: menu.id, description: newDescription });
	}

	async function handleCategoryRenamed(categoryId: string, newName: string) {
		return context.changeCategoryTitle({ menuId: menu.id, categoryId, title: newName });
	}	
	
	async function handleCategoryDescriptionChanged(categoryId: string, newDescription: string) {
		return context.changeCategoryDescription({ menuId: menu.id, categoryId, description: newDescription });
	}

	async function handleCategorySwapUp(category: MenuCategory) {
		const other = Object.values(menu.categories).find(c => c.index === category.index - 1);
		if(other) {
			await context.swapMenuCategories({ menuId: menu.id, categoryOneId: category.id, categoryTwoId: other.id })
		}
	}

	async function handleCategorySwapDown(category: MenuCategory) {
		const other = Object.values(menu.categories).find(c => c.index === category.index + 1);
		if(other) {
			await context.swapMenuCategories({menuId: menu.id, categoryOneId: category.id, categoryTwoId: other.id })
		}
	}

	async function promptConfirmDelete(category: MenuCategory) {
		Modal.confirm({
			title: "Are you sure you want to delete this category?",
			icon: <ExclamationCircleOutlined />,
			content: "The category and ALL of its entries will be permanently deleted",
			onOk: () => context.removeMenuCategory({ menuId: menu.id, categoryId: category.id }),
			okType: "danger"
		});
	}

	async function handleEntrySwapUp(category: MenuCategory, entry: CategoryEntry) {
		const other = Object.values(category.entries).find(c => c.index === entry.index - 1);
		if(other) {
			await context.swapMenuEntries({ menuId: menu.id, categoryId: category.id, entryOneId: entry.id, entryTwoId: other.id })
		}
	}
	async function handleEntrySwapDown(category: MenuCategory, entry: CategoryEntry) {
		const other = Object.values(category.entries).find(c => c.index === entry.index + 1);
		if(other) {
			await context.swapMenuEntries({ menuId: menu.id, categoryId: category.id, entryOneId: entry.id, entryTwoId: other.id })
		}
	}
	function promptEntryDelete(category: MenuCategory, entry: CategoryEntry) {
		Modal.confirm({
			title: "Are you sure you want to delete this entry?",
			icon: <ExclamationCircleOutlined />,
			content: "The entry will be permanently deleted",
			onOk: () => context.removeCategoryEntry({ menuId: menu.id, categoryId: category.id, entryId: entry.id }),
			okType: "danger"
		});
	}

	async function handleEntryRenamed(category: MenuCategory, entry: CategoryEntry, newName: string) {
		return context.changeEntryName({menuId: menu.id, categoryId: category.id, entryId: entry.id, name: newName})
	}
	async function handleEntryDescriptionChanged(category: MenuCategory, entry: CategoryEntry, newDesc: string)  {
		return context.changeEntryDescription({menuId: menu.id, categoryId: category.id, entryId: entry.id, description: newDesc })
	}
	async function handleEntryPriceChanged(category: MenuCategory, entry: CategoryEntry, newPrice: string)  {
		return context.changeEntryPrice({menuId: menu.id, categoryId: category.id, entryId: entry.id, price: newPrice })
	}

	return <Space direction="vertical" size="large" style={{ width: "100%" }}>
		<Card title="Menu info">
			<ModifableText 
				label="Title" 
				text={menu.title} 
				rules={menuNameRules} 
				onFinish={handleTitleChanged} 
			/>
			<br/>
			<ModifableText 
				label="Description" 
				text={menu.description ?? ""} 
				rules={menuDescriptionRules} 
				onFinish={handleDescriptionChanged} 
			/>
		</Card>
		<Divider>Categories</Divider>
		<FlipMove>
		{Object.values(menu.categories)
			.sort((a, b) => a.index - b.index)
			.map((cat, idx, ar) => (
				<div key={cat.id} style={{ marginBottom: 50 }}>
					<Card 
						title={<ModifableText 
							label="Category"
							rules={categoryNameRules} 
							onFinish={n => handleCategoryRenamed(cat.id, n)} 
							text={cat.title} 
						/>}
						actions={[
								<AsyncButton type="text" icon={<UpOutlined/>} disabled={idx === 0} onClick={() => handleCategorySwapUp(cat)} />
							,	<AsyncButton type="text" icon={<DownOutlined/>} disabled={idx === ar.length - 1} onClick={() => handleCategorySwapDown(cat)} />
							, <AddEntryModal menu={menu} category={cat} />
							,	<Button type="text" style={{ color: "red" }} icon={<MinusOutlined/>} onClick={() => promptConfirmDelete(cat) }/>
						]}
					>
						<Meta 
							description={
								<ModifableText 
									label="Category description"
									missingTextButtonChildren={"Add category description"}
									rules={categoryDescriptionRules} 
									onFinish={n => handleCategoryDescriptionChanged(cat.id, n)} 
									text={cat.description ?? ""} 
								/>
							}
						/>
						<div style={{ marginTop: 18, marginBottom: 10 }}>
							<Typography.Text>Category entries:</Typography.Text>
						</div>
						<FlipMove>
							{Object.values(cat.entries).sort((a, b) => a.index - b.index).map((item, index) => (
								<div key={item.id}>
									<Divider style={{ margin: 0 }} />
									<List.Item
										actions={[
												<AsyncButton 
													disabled={index === 0} 
													type="text" 
													icon={<UpOutlined />} 
													onClick={() => handleEntrySwapUp(cat, item)}
												/>
											, <AsyncButton 
													disabled={index === Object.values(cat.entries).length - 1} 
													type="text" icon={<DownOutlined />} 
													onClick={() => handleEntrySwapDown(cat, item)}
												/>
											, <Button 
													type="text" 
													icon={<MinusOutlined style={{ color: "orange" }} />} 
													onClick={() => promptEntryDelete(cat, item)}
												/>
										]}
									>
										<List.Item.Meta
											title={<ModifableText 
												text={item.name} 
												rules={entryNameRules} 
												label={"Entry name"} 
												onFinish={(newName) => handleEntryRenamed(cat, item, newName)} 
												hideInlineLabel={true}
											/>}
											description={<>
												<ModifableText 
													text={item.description ?? ""} 
													rules={entryDescriptionRules} 
													label={"Entry description"} 
													missingTextButtonChildren={"Add description"}
													onFinish={(newDesc) => handleEntryDescriptionChanged(cat, item, newDesc)} 
													hideInlineLabel={true}
												/>
												<br/>
												<ModifableText 
													text={item.price ?? ""} 
													rules={entryPriceRules} 
													label={"Price"} 
													missingTextButtonChildren={"Add price"}
													onFinish={(newPrice) => handleEntryPriceChanged(cat, item, newPrice)} 
													hideInlineLabel={true}
												/>
											</>}
										/>
									</List.Item>
								</div>
							))}
						</FlipMove>
					</Card>
				</div>
			))
		}
		</FlipMove>
		<div style={{ display: "flex", justifyContent: "center" }}>
			<AddCategoryModal menu={menu} />
		</div>
		<Divider />
	</Space>
}