import React, { useEffect, useContext } from "react"
import { AppContext } from "../context/AppContext";
import { useHistory, Switch, Route, useRouteMatch, Link } from "react-router-dom";
import Menu from "./Dashboard/Menu"
import Restaurant from "./Dashboard/Restaurant"
import Login from "./Dashboard/Login"
import Register from "./Dashboard/Register";
import Subscription from "./Dashboard/Subscription";
import Profile from "./Dashboard/Profile";
import { ReactComponent as RestaurantLogo } from '../svg/business-outline.svg';
import { ReactComponent as MenuLogo } from '../svg/book-outline.svg';
import { ReactComponent as SubscriptionLogo } from '../svg/card-outline.svg';
import { ReactComponent as ProfileLogo } from '../svg/person-outline.svg';



export default function Dashboard() {
	const appContext = useContext(AppContext);
	const history = useHistory();
	const { path } = useRouteMatch();
	useEffect(() => {
		appContext.auth.ifPresentOrElse(
			() => {
				if(history.location.pathname.includes("login")
					|| history.location.pathname.includes("register")) {
					history.push("/dashboard")
				}
			}
			, () => {
				if(!history.location.pathname.includes("login")) {
					history.push(`${path}/login`);
				}
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appContext.auth]);

	return <Switch>
		<Route path={`${path}/login`}>
			<Login />
		</Route>
		<Route path={`${path}/register`}>
			<Register />
		</Route>
		<Route path={`${path}`}>
			<Main />
		</Route>
	</Switch>;
}

function TabButton({ Logo, to, title }: 
	{ 
			Logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
		, to: string
		, title: string
	}
) {
	const { path } = useRouteMatch();
	const om = useRouteMatch(path + to);

	return <Link 
		to={path + to} 
		style={{ 
				display: "flex"
			, flexDirection: "column"
			, justifyContent: "center"
			, alignItems: "center"
			, paddingTop: 3
			, paddingBottom: 3
			, flex: 1 
			, opacity: om?.isExact? 1 : 0.65
			, ...(om?.isExact ? { fontWeight: 600 } : {})
			, color: "black"
			, textDecoration: "none"
		}}
	>
		<Logo width={30} height={30}  /> 
		{title}
	</Link>
}

function KeepMounted(ch: JSX.Element) {
	return (m : any) => <div style={{ flex: 1, width: "100%", display: "flex", ...(m.match ? {} : { display: "none" })}}>{ch}</div>
}

function Main() {
	const { path } = useRouteMatch();

	return <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
		<div style={{ flex: 1, paddingBottom: 80, backgroundColor: "#f0f0f0" }}>
		
		{/* <Switch> */}
			<Route children={KeepMounted(<Menu/>)} path={`${path}/menu`}/>
			<Route component={Subscription} path={`${path}/subscription`} />
			<Route children={KeepMounted(<Profile/>)} path={`${path}/profile`} />
			<Route children={KeepMounted(<Restaurant/>)} exact path={`${path}/`} />
		{/* </Switch> */}
	</div>
	<div
			style={{
					position: "fixed"
				,	bottom: 0
				,	left: 0
				,	right: 0
				, height: 80
				, display: "flex"
				, justifyContent: "space-between"
				, borderTop: "1px solid lightgray"
				, backgroundColor: "#fff"
			}}
		>
			<TabButton to="/" title="Restaurant" Logo={RestaurantLogo} />
			<TabButton to="/menu" title="Menu" Logo={MenuLogo} />
			<TabButton to="/subscription" title="Subscription" Logo={SubscriptionLogo} />
			<TabButton to="/profile" title="Profile" Logo={ProfileLogo} />
		</div>
	</div>
}
