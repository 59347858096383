import React, { useState, ReactNode } from 'react'
import { Modal, Button, Input, Form, Space, message } from 'antd'
import { EditOutlined } from "@ant-design/icons"
import AsyncButton from './AsyncButton';
import { Rule } from 'antd/lib/form';
import TextArea from "antd/lib/input/TextArea";

export default function ModifableText({ label, text, rules, onFinish, multiline, hideInlineLabel, missingTextButtonChildren }: { missingTextButtonChildren?: ReactNode,  hideInlineLabel?: boolean, multiline?: boolean, label: string, text: string, rules: Rule[], onFinish: (newValue: string) => Promise<void>  }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  async function handleConfirmClicked() {
    setLoading(true);
    const newValue = form.getFieldValue("mainField");
    try {
      await onFinish(newValue);
      message.success(label + " updated successfully")
      setIsModalVisible(false);
    } catch(e) {
      message.error("There was an error");
    }
    setLoading(false);
  }

  return <>
    {text === "" && missingTextButtonChildren
      ? <Button children={missingTextButtonChildren} style={{ opacity: 0.5 }}  type="text" shape={"circle"} onClick={() => setIsModalVisible(true)} />
      : <>{hideInlineLabel ? text : (<span>{label}: {text}</span>)}
          <Button icon={<EditOutlined />} type="text" shape={"circle"} onClick={() => setIsModalVisible(true)} />
        </>
    }
    <Modal
      title={`Modify ${label}`}
      centered={true}
      forceRender={true}
      visible={isModalVisible}
      footer={null}
      onCancel={() => setIsModalVisible(false)}
      destroyOnClose={true}
    >
      <Form form={form} onFinish={handleConfirmClicked} preserve={false}>
        <Form.Item
          label={label}
          name="mainField"
          rules={rules}
          initialValue={text}
        >
          {multiline ? 
              <TextArea autoFocus />
            : <Input autoFocus />
          }
        </Form.Item>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
          </Form.Item>
          <Form.Item>
            <AsyncButton
              loading={loading}
              type="primary" 
              htmlType="submit"
            >
              Ok
            </AsyncButton>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  </>
}
