import React, { useContext, useState } from "react"
import { AppContext } from "../../../context/AppContext";
import { Form, message, Button, Modal, Input, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AsyncButton from "../../../components/AsyncButton";
import { menuNameRules, menuDescriptionRules } from "./common";

export default function AddMenuModal() {
	const context = useContext(AppContext);
	const [modalVisible, setModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	async function handleSubmit() {
		setLoading(true);
		const name = form.getFieldValue("name");
		const description = form.getFieldValue("description");
		await context.createMenu({
			title: name,
			description
		});
		message.success("Menu added successfully");
		setModalVisible(false);
		setLoading(false);
	}
	return (
		<>
			<Button type="primary" onClick={() => setModalVisible(true)}>Add Menu</Button>
			<Modal
				title="Add new menu"
				centered={true}
				forceRender={true}
				visible={modalVisible}
				footer={null}
				onCancel={() => setModalVisible(false)}
				destroyOnClose={true}
			>
				<Form layout="vertical" form={form} onFinish={handleSubmit} preserve={false}>
					<Form.Item
						label="Menu name"
						name="name"
						rules={menuNameRules}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={menuDescriptionRules}
					>
						<TextArea />
					</Form.Item>
					<Space style={{ display: "flex", justifyContent: "flex-end" }}>
						<Form.Item>
							<Button onClick={() => setModalVisible(false)}>Cancel</Button>
						</Form.Item>
						<Form.Item>
							<AsyncButton
								loading={loading}
								type="primary" 
								htmlType="submit"
							>
								Add
							</AsyncButton>
						</Form.Item>
					</Space>
				</Form>
			</Modal>
		</>
	)
}
