import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Dashboard from "./pages/Dashboard"
import Public from "./pages/Public"
import { AppContextProvider } from "./context/AppContext"

export default function App() {
  return (
    <Router>
      <AppContextProvider>
        <div>
          <Switch>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/public/:id">
              <Public />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </AppContextProvider>
    </Router>
  );
}

function Home() {
  return <div>
    <nav style={{ display: "flex", flexDirection: "row" }}>
      <Link style={{ marginRight: 10 }} to="/">Home</Link>
      <Link style={{ marginRight: 10 }} to="/dashboard">Dashboard</Link>
    </nav>
    <h2>Landing page</h2>
  </div>
}
