import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Optional } from "../../../util";
import AsyncButton from "../../../components/AsyncButton";
import { Select, Space, message } from "antd";
import { Link } from "react-router-dom";
export function MenuChanger({ currentMenuId, restaurantId }: { currentMenuId?: string; restaurantId: string; }) {
	const context = useContext(AppContext);
	const [selectedMenu, setSelectedMenu] = useState<Optional<Menu>>(Optional.maybe(currentMenuId ? context.menus[currentMenuId] : undefined));


	async function handleChosePressed() {
		return selectedMenu.map(
				async m => {
					try {
						await context.changeRestaurantMenu({ menuId: m.id, restaurantId })
						message.success("Menu changed successfully");
					} catch(e) {
						console.error(e);
						message.error("Menu change failed")
					}
				}
			, () => message.error("Menu not selected")
		);
	}

	return <Space direction="vertical">
		<Select
				style={{ minWidth: 200 }}
				placeholder="Select a menu"
				value={selectedMenu.get()?.id}
				notFoundContent={<div>No menus found. Create a menu from <Link to="dashboard/menu">here</Link></div>}
				options={Object.values(context.menus)
					.map(menu => ({ label: menu.title, value: menu.id }))
				}
				onChange={e => setSelectedMenu(
					Optional.maybe(context.menus[e.toString()])
				)}
		/>
		<AsyncButton
			disabled={selectedMenu.map(sel => sel.id === currentMenuId, () => true)}
			onClick={handleChosePressed}
		>
			Chose this one
		</AsyncButton>
	</Space>;
}
