import React, { useState, useContext } from "react";
import AsyncButton from "../../../components/AsyncButton";
import { AppContext } from "../../../context/AppContext";
import { Modal, Button, Input, Form, Space, Upload, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from '@ant-design/icons';
import { uploadRestaurantImage, restaurantNameRules, restaurantDescriptionRules } from "./common";

export default function CreateRestaurantModal() {
	const context = useContext(AppContext);
	const [modalVisible, setModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [fileList, setFileList] = useState<UploadFile<any>[]>([])

	async function handleCreateRestaurantClicked() {
		setLoading(true);
		if(!form.getFieldsError().some(v => v.errors && v.errors.length > 0)) {
			const name = form.getFieldValue("name");
			const description = form.getFieldValue("description");
			const imagesUrls = await Promise.all(fileList.map(v => uploadRestaurantImage(context.auth.getOrThrow().uid, v.originFileObj!)));
			await context.createRestaurant({
				name,
				description,
				imagesUrls
			});
			message.success("Restaurant added successfully");
			setModalVisible(false);
		}
		setLoading(false);
	}

	function handleImageUpload(info: UploadChangeParam<UploadFile<any>>) {
		setFileList(info.fileList);
	}

	return (
		<>
			<Button type="primary" onClick={() => setModalVisible(true)} >Add restaurant</Button>
			<Modal
				title="Add new restaurant"
				centered={true}
				forceRender={true}
				visible={modalVisible}
				footer={null}
				onCancel={() => setModalVisible(false)}
				destroyOnClose={true}
			>
				<Form layout="vertical" form={form} onFinish={handleCreateRestaurantClicked}>
					<Form.Item
						label="Restaurant name"
						name="name"
						rules={restaurantNameRules}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={restaurantDescriptionRules}
					>
						<TextArea />
					</Form.Item>
					<Form.Item label="Restaurant images (up to 4)">
						<Upload
							beforeUpload={() => false}
							multiple={false}
							accept="image/png,image/jpeg"
							onChange={handleImageUpload}
							listType="picture-card"
						>
							{fileList.length < 4 && <><UploadOutlined /> Click to Upload</>}
						</Upload>
					</Form.Item>
					<Space style={{ display: "flex", justifyContent: "flex-end" }}>
						<Form.Item>
							<Button onClick={() => setModalVisible(false)}>Cancel</Button>
						</Form.Item>
						<Form.Item>
							<AsyncButton
								loading={loading}
								type="primary" 
								htmlType="submit"
							>
								Create
							</AsyncButton>
						</Form.Item>
					</Space>
				</Form>
			</Modal>
		</>
	)
}
