export class Optional<T> {
	value: T | null = null;
	static Just<T>(v : T) {
		const r = new Optional<T>();
		r.value = v;
		return r;
	}
	static Nothing<T>() {
		const r = new Optional<T>();
		r.value = null;
		return r;
	}
	static maybe<T>(v: T | undefined): Optional<T> {
		return v === undefined || v === null ? this.Nothing<T>() : this.Just<T>(v);
	}
	isPresent() { 
		return this.value !== null; 
	}
	map<E, U>(ifp: (x : T) => E, ife: () => U) {
		return this.value === null ? ife() : ifp(this.value);
	}
	ifPresentOrElse(ifp: (x : T) => void, ife: () => void) {
		this.map(ifp, ife);
	}
	ifPresent(ifp: (x : T) => void) {
		this.ifPresentOrElse(ifp, () => {});
	}
	ifAbsent(ifa: () => void) {
		this.ifPresentOrElse(() => {}, ifa);
	}
	getOrThrow() {
		if(this.value === null) {
			throw new Error("Accessing optional nothing");
		}
		return this.value;
	}
	get() { 
		return this.value; 
	}
	orElse<U>(x: U) { 
		return this.map(v => v, () => x);
	}
	ifMap<E, U>(P: (x : T) => boolean, ifs: (x: T) => E, ifn: () => U) {
		return this.map(x => P(x) ? ifs(x) : ifn(), ifn);
	}
}