import React, { useContext, useState } from "react"
import { AppContext } from "../../../context/AppContext";
import { Form, message, Button, Modal, Input, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AsyncButton from "../../../components/AsyncButton";
import { categoryNameRules, categoryDescriptionRules, entryNameRules, entryDescriptionRules, entryPriceRules } from "./common";
import { PlusOutlined } from "@ant-design/icons";

export default function AddEntryModal({ menu, category }: { menu: Menu, category: MenuCategory }) {
	const context = useContext(AppContext);
	const [modalVisible, setModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	async function handleSubmit() {
		setLoading(true);
		const name = form.getFieldValue("name");
		const description = form.getFieldValue("description");
		const price = form.getFieldValue("price");
		try {
			await context.addEntry({ 
				menuId: menu.id, 
				categoryId: category.id, 
				name, 
				description,
				price,
				index: Object.keys(menu.categories[category.id].entries).length
			});
			message.success("Category added successfully");
			setModalVisible(false);
			setLoading(false);
		} catch(er) {
			console.error(er);
			message.error("There was an error...");	
			setLoading(false);
		}
	}
	return (
		<>
			<Button type="text" onClick={() => setModalVisible(true)} icon={<PlusOutlined />} />
			<Modal
				title="Add new entry"
				centered={true}
				forceRender={true}
				visible={modalVisible}
				footer={null}
				onCancel={() => setModalVisible(false)}
				destroyOnClose={true}
			>
				<Form layout="vertical" form={form} onFinish={handleSubmit} preserve={false}>
					<Form.Item
						label="Entry name"
						name="name"
						rules={entryNameRules}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={entryDescriptionRules}
					>
						<TextArea />
					</Form.Item>
					<Form.Item
						label="Price"
						name="price"
						rules={entryPriceRules}
					>
						<Input type="text" />
					</Form.Item>
					<Space style={{ display: "flex", justifyContent: "flex-end" }}>
						<Form.Item>
							<Button onClick={() => setModalVisible(false)}>Cancel</Button>
						</Form.Item>
						<Form.Item>
							<AsyncButton
								loading={loading}
								type="primary" 
								htmlType="submit"
							>
								Add
							</AsyncButton>
						</Form.Item>
					</Space>
				</Form>
			</Modal>
		</>
	)
}
