import React, { useContext, useState, useEffect } from "react"
import { AppContext } from "../../context/AppContext";
import { Optional } from "../../util";
import { Select } from 'antd';
import { RestaurantView } from "./Restaurant/RestaurantView";
import CreateRestaurantModal from "./Restaurant/CreateRestaurantModal"


export default function Restaurant() {
	const context = useContext(AppContext);
	const [selectedRestaurant, setSelectedRestaurant] = useState<Optional<Restaurant>>(Optional.Nothing());

	useEffect(() => {
		selectedRestaurant.ifAbsent(() => {
			setSelectedRestaurant(
				Optional.maybe(
					Object.values(context.restaurants)
						.sort((a, b) => b.createdAt - a.createdAt)[0]
				)
			)
		});
	}, [context.restaurants]);

	function handleRestaurantChanged(id: string) {
		setSelectedRestaurant(Optional.maybe(context.restaurants[id]));
	}

	return <div style={{ flex: 1 }}>
		<div 
			style={{ 
					display: "flex"
				, flexDirection: "row"
				, justifyContent: "space-between"
				, borderBottom: "1px solid lightgray"
				, backgroundColor: "#fff"
				, padding: 5
			}}
		>
			<div>
				<Select
					style={{ minWidth: 200 }}
					notFoundContent={<div>
						No restaurants found
					</div>}
					placeholder="Select a restaurant"
					value={selectedRestaurant.get()?.id}
					options={Object.values(context.restaurants)
						.sort((a, b) => b.createdAt - a.createdAt)
						.map(rest => ({ label: rest.name, value: rest.id }))
					}
					onChange={e => handleRestaurantChanged(e.toString())}
				/>
			</div>
			<div>
				<CreateRestaurantModal />
			</div>
		</div>
		<div style={{paddingRight: 10, paddingLeft: 10}}>
			{selectedRestaurant.map(x => <RestaurantView restaurant={x} />, () => "No restaurant selected")}
		</div>
	</div>
}


