import { RuleObject } from "antd/lib/form";

export const menuNameRules : RuleObject[] = [
  {
    required: true,
    message: "Menu name must be between 3 and 40 characters",
    min: 3,
    max: 40
  }
];

export const menuDescriptionRules : RuleObject[] = [
  {
    required: false,
    message: "Menu description must be at most 300 characters long",
    max: 300
  }
]

export const categoryNameRules : RuleObject[] = [
  {
    required: true,
    message: "Category name must be between 3 and 40 characters",
    min: 3,
    max: 40
  }
];
export const categoryDescriptionRules : RuleObject[] = [
  {
    required: false,
    message: "Category description must be at most 300 characters long",
    max: 300
  }
]

export const entryNameRules : RuleObject[] = [
  {
    required: true,
    message: "Entry name must be between 3 and 40 characters",
    min: 3,
    max: 40
  }
];
export const entryDescriptionRules : RuleObject[] = [
  {
    required: false,
    message: "Entry description must be at most 300 characters long",
    max: 300
  }
]

export const entryPriceRules : RuleObject[] = [
  {
    required: false,
    message: "Price too long",
    max: 20
  }
]