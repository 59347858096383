import firebase from "firebase";
import { v4 as uuidv4 } from 'uuid';
import { RuleObject } from "antd/lib/form";

export function restaurantUrl(restaurantId: string) {
	return `${window.origin}/public/${restaurantId}`;
}

export async function uploadRestaurantImage(uid: string, file: Blob | Uint8Array | ArrayBuffer) {
  return await firebase.storage()
    .ref(`users/${uid}/restaurants/${uuidv4()}`)
    .put(file)
    .then(r => r.ref.getDownloadURL());
}

export const restaurantNameRules : RuleObject[] = [
  {
    required: true,
    message: "Restaurant name must be between 3 and 40 characters",
    min: 3,
    max: 40
  }
];

export const restaurantDescriptionRules : RuleObject[] = [
  {
    required: false,
    message: "Restaurant description must be at most 300 characters long",
    max: 300
  }
]