import React, { createContext, useEffect, useState, useRef } from "react";
import firebase, { User } from "firebase";
import { Optional } from "../util";

firebase.auth().useDeviceLanguage();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

function firebaseCallable<U>(name: string): () => Promise<U> {
	return async () => {
		const res = await firebase.functions().httpsCallable(name)({});
		return res.data;
	};
}

function firebaseFunction<T, U>(name: string): (a: T) => Promise<U> {
	const f = firebase.functions().httpsCallable(name);
	return async (data: T) => {
		const res = await f(data);
		return res.data;
	}
}

const createMenu = firebaseFunction<{ title: string, description?: string }, string>("createMenu")
const addCategory = firebaseFunction<{ menuId: string, title: string, index: number, description?: string }, string>("addCategory")
const addEntry = firebaseFunction<{ menuId: string, categoryId: string, price?: string, name: string, index: number, description?: string }, string>("addEntry")
const createSubscription = firebaseFunction<
	{ priceId: string, paymentMethodId: string }, 
	StripeSubscription	
>("createSubscription");
const retrieveCurrentSubscription = firebaseCallable<StripeSubscription>("retrieveCurrentSubscription")
const cancelCurrentSubscription = firebaseCallable<any>("cancelCurrentSubscription");
const reactivateCurrentSubscription = firebaseCallable<any>("reactivateCurrentSubscription");
const createRestaurant = firebaseFunction<{ 
	name: string, 
	description?: string,
	imagesUrls?: string[]
}, string>("createRestaurant");
const changeRestaurantMenu = firebaseFunction<{ restaurantId: string, menuId: string }, any>("changeRestaurantMenu");
const removeRestaurantImage = firebaseFunction<{ restaurantId: string, imageUrl: string }, any>("removeRestaurantImage");
const addRestaurantImage = firebaseFunction<{ restaurantId: string, imageUrl: string }, any>("addRestaurantImage");
const changeRestaurantName = firebaseFunction<{ restaurantId: string, name: string }, any>("changeRestaurantName");
const changeRestaurantDescription = firebaseFunction<{ restaurantId: string, description: string }, any>("changeRestaurantDescription");
const changeMenuTitle = firebaseFunction<{ menuId: string, title: string }, any>("changeMenuTitle");
const changeMenuDescription = firebaseFunction<{ menuId: string, description?: string }, any>("changeMenuDescription");
const swapMenuCategories = firebaseFunction<{ menuId: string, categoryOneId: string, categoryTwoId: string }, any>("swapMenuCategories");
const removeMenuCategory = firebaseFunction<{ menuId: string, categoryId: string }, any>("removeMenuCategory");
const changeCategoryTitle = firebaseFunction<{ menuId: string, categoryId: string, title: string }, any>("changeCategoryTitle");
const changeCategoryDescription = firebaseFunction<{ menuId: string, categoryId: string, description: string }, any>("changeCategoryDescription");
const swapMenuEntries = firebaseFunction<{ menuId: string, categoryId: string, entryOneId: string, entryTwoId: string }, any>("swapMenuEntries")
const removeCategoryEntry = firebaseFunction<{ menuId: string, categoryId: string, entryId: string }, any>("removeCategoryEntry");
const changeEntryName = firebaseFunction<{ menuId: string, categoryId: string, entryId: string, name: string }, any>("changeEntryName");
const changeEntryDescription = firebaseFunction<{ menuId: string, categoryId: string, entryId: string, description: string }, any>("changeEntryDescription");
const changeEntryPrice = firebaseFunction<{ menuId: string, categoryId: string, entryId: string, price: string }, any>("changeEntryPrice");

export const AppContext = createContext<{ 
	auth: Optional<User>
	account: Optional<CustomerAccount>,
	logout: ReturnType<typeof firebase.auth>["signOut"],
	menus: { [id: string]: Menu },
	restaurants: { [id: string]: Restaurant },
	register: (email: string, password: string) => Promise<firebase.auth.UserCredential>,
	login: (email: string, password: string) => Promise<firebase.auth.UserCredential>,
	loginGoogle: () => Promise<firebase.auth.UserCredential>,
	createMenu: typeof createMenu,
	addCategory: typeof addCategory,
	addEntry: typeof addEntry,
	createSubscription: typeof createSubscription,
	retrieveCurrentSubscription: typeof retrieveCurrentSubscription,
	cancelCurrentSubscription: typeof cancelCurrentSubscription,
	reactivateCurrentSubscription: typeof reactivateCurrentSubscription,
	createRestaurant: typeof createRestaurant,
	changeRestaurantMenu: typeof changeRestaurantMenu,
	removeRestaurantImage: typeof removeRestaurantImage,
	addRestaurantImage: typeof addRestaurantImage,
	changeRestaurantName: typeof changeRestaurantName,
	changeRestaurantDescription: typeof changeRestaurantDescription,
	changeMenuTitle: typeof changeMenuTitle,
	changeMenuDescription: typeof changeMenuDescription,
	swapMenuCategories: typeof swapMenuCategories,
	removeMenuCategory: typeof removeMenuCategory,
	changeCategoryTitle: typeof changeCategoryTitle,
	changeCategoryDescription: typeof changeCategoryDescription,
	swapMenuEntries: typeof swapMenuEntries,
	removeCategoryEntry: typeof removeCategoryEntry,
	changeEntryName: typeof changeEntryName,
	changeEntryDescription: typeof changeEntryDescription,
	changeEntryPrice: typeof changeEntryPrice,
}>(null as any);

type FirebaseUnsubscriptions = { 
	categories: { [id: string]: () => void }, 
	entires: { [id: string]: () => void },
	menus: () => void,
	account: () => void,
	restaurants: () => void
};

const defaultSubscriptions: FirebaseUnsubscriptions = { 
	categories: {}, 
	menus: () => {}, 
	entires: {}, 
	account: () => {},
	restaurants: () => {}
}

export function AppContextProvider({ children }: any) {
	const [auth, setAuth] = useState<Optional<User>>(Optional.Nothing());
	const [account, setAccount] = useState<Optional<CustomerAccount>>(Optional.Nothing());
	const [menus, setMenus] = useState<{ [id: string]: Menu }>({});
	const [restaurants, setRestaurants] = useState<{ [id: string]: Restaurant}>({});
	const subscriptions = useRef<FirebaseUnsubscriptions>(defaultSubscriptions);

	useEffect(() => {
		function subscribeEntries(menuId: string, categoryId: string) {
			subscriptions.current.entires[menuId + categoryId] = 
				firebase.firestore()
					.collection("menu")
					.doc(menuId)
					.collection("categories")
					.doc(categoryId)
					.collection("entries")
					.onSnapshot(snap => {
						snap.docChanges().forEach(change => {
							if(change.type === "added") {
								menus[menuId].categories[categoryId].entries[change.doc.id] = Object.assign(
									{
										id: change.doc.id,
										name: "",
										index: 0,
										description: ""
									}
									, change.doc.data()
								);
							} else if(change.type === "removed") {
								delete menus[menuId].categories[categoryId].entries[change.doc.id];
							} else {
								Object.assign(
										menus[menuId].categories[categoryId].entries[change.doc.id]
									, change.doc.data()
								);
							}
						});
						setMenus({ ...menus });
					});
		}

		function subscribeCategories(menuId: string) {
			subscriptions.current.categories[menuId] =
			 firebase.firestore()
				.collection("menu")
				.doc(menuId)
				.collection("categories")
				.onSnapshot(snap => {
					snap.docChanges().forEach(change => {
						if(change.type === "added") {
							menus[menuId].categories[change.doc.id] = Object.assign(
								{
									id: change.doc.id,
									entries: {},
									index: 0,
									title: ""
								}
								, change.doc.data()
							);
							subscribeEntries(menuId, change.doc.id);
						} else if(change.type === "removed") {
							delete menus[menuId].categories[change.doc.id];
						} else {
							Object.assign(menus[menuId].categories[change.doc.id], change.doc.data())
						}
					});
					setMenus({ ...menus });
				})
		}
		
		function subscribeMenus(uid: string) {
			subscriptions.current.menus = firebase.firestore()
				.collection("menu")
				.where("ownerId", "==", uid)
				.onSnapshot(snap => {
					snap.docChanges().forEach(change => {
						if(change.type === "added") {
							menus[change.doc.id] = Object.assign(
								{
									id: change.doc.id,
									title: "",
									description: "",
									categories: {},
									ownerId: uid,
									createdAt: Date.now(),
								}
								, change.doc.data()
							);
							subscribeCategories(change.doc.id);
						} else if(change.type === "removed") {
							delete menus[change.doc.id];
						} else {
							Object.assign(menus[change.doc.id], change.doc.data());
						}
					});
					setMenus({ ...menus });
			});
		}

		function subscribeAccount(uid: string) {
			subscriptions.current.account = firebase.firestore()
				.collection("account")
				.doc(uid)
				.onSnapshot(snap => {
					setAccount(snap.exists ? Optional.Just(snap.data() as CustomerAccount) : Optional.Nothing())
				})
		}

		function subscribeRestaurant(uid: string) {
			subscriptions.current.restaurants = firebase.firestore()
				.collection("restaurant")
				.where("ownerId", "==", uid)
				.onSnapshot(snap => {
					snap.docChanges().forEach(change => {
						if(change.type === "added") {
							restaurants[change.doc.id] = {
								id: change.doc.id,
								name: "",
								ownerId: uid,
								createdAt: Date.now(),
								...change.doc.data()
							};
						} else if(change.type === "removed") {
							delete restaurants[change.doc.id];
						} else {
							Object.assign(restaurants[change.doc.id], change.doc.data())
						}
					});
					setRestaurants({ ...restaurants });
				});
		}

		firebase.auth().onAuthStateChanged(user => {
			if(user === null) {
				setAuth(Optional.Nothing());
				setMenus({});
				setRestaurants({});
				setAccount(Optional.Nothing()); 
				subscriptions.current.menus();
				subscriptions.current.account();
				Object.values(subscriptions.current.categories).forEach(f => f());
				Object.values(subscriptions.current.entires).forEach(f => f());
				subscriptions.current = defaultSubscriptions;
			} else {
				setAuth(Optional.Just(user));
				subscribeMenus(user.uid)
				subscribeAccount(user.uid);
				subscribeRestaurant(user.uid);
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function login(email: string, password: string) {
		return firebase.auth().signInWithEmailAndPassword(email, password);
	}

	return <AppContext.Provider
		value={{
			auth,
			login,
			register: (email: string, password: string) => firebase.auth().createUserWithEmailAndPassword(email, password),
			loginGoogle: () => firebase.auth().signInWithPopup(googleAuthProvider),
			logout: () => firebase.auth().signOut(),
			menus,
			restaurants,
			createMenu,
			addCategory,
			addEntry,
			account,
			createSubscription,
			retrieveCurrentSubscription,
			cancelCurrentSubscription,
			reactivateCurrentSubscription,
			createRestaurant,
			changeRestaurantMenu,
			removeRestaurantImage,
			addRestaurantImage,
			changeRestaurantName,
			changeRestaurantDescription,
			changeMenuDescription,
			changeMenuTitle,
			swapMenuCategories,
			removeMenuCategory,
			changeCategoryTitle,
			changeCategoryDescription,
			swapMenuEntries,
			removeCategoryEntry,
			changeEntryName,
			changeEntryDescription,
			changeEntryPrice
		}}
	>
		{children}
	</AppContext.Provider>
}