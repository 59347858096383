import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBK3vIPuDPHGtifzN5v3EEbi7jjCx5Y7ls",
    authDomain: "lumenu-master.firebaseapp.com",
    databaseURL: "https://lumenu-master.firebaseio.com",
    projectId: "lumenu-master",
    storageBucket: "lumenu-master.appspot.com",
    // messagingSenderId: "448002439795",
    appId: "1:448002439795:web:65a4bf45eb39d96c4e0806",
    // measurementId: "G-CVX0BGHG55"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence().catch(er => console.error("Couldn't enable persistance", er));
// firebase.functions().useFunctionsEmulator("http://192.168.1.90:5001");
